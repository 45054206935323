

module.exports = {
    init: function () {

        $('#unavailable-modal').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget)
            var recipient = button.data('pid')
            var modal = $(this)
            modal.find('.productId').val(recipient)
            $('.stickyHeader').css('z-index', '1');
        })

        $('#unavailable-modal').on('hidden.bs.modal', function (event) {
            $('.stickyHeader').removeAttr('style');
        })

        if (document.querySelector('.unavailable-button-wrapper')) {
            $(document).ready(function () {
                $('.error-navailable-name').css('display', 'none');
                $('.error-navailable-email').css('display', 'none');

                $('.button-quarternary').on('click', function () {
                    var postalCode = $(this).attr('data-postalcode');
                    var state = $(this).attr('data-state');
                    var name = $('.unavailable-name').val();
                    var productid = $('.productId').val();
                    var email = $('.unavailable-email').val();
                    var url = $('.unavailable-email').attr('data-url');
                    var form = {
                        postalCode: postalCode,
                        state: state,
                        name: name,
                        email: email,
                        productid: productid,
                        id: `${email}_${postalCode}_${productid}`
                    };
                    if ((validateInput(name, email)) && (productid != undefined)) {
                        $.spinner().start();

                        $.ajax({
                            url: url,
                            method: 'post',
                            dataType: 'json',
                            data: form,
                            success: function (data) {
                                if (data.error) {
                                    console.log('error');
                                    if ($('.add-to-cart-messages').length === 0) {
                                        $('body').append(
                                            '<div class="add-to-cart-messages"></div>'
                                        );
                                    }
                                    $('#unavailable-modal').modal('hide');
                                    $('.add-to-cart-messages').append(
                                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                                        + ' role="alert">'
                                        + data.msgError + '</div>'
                                    );
        
                                    setTimeout(function () {
                                        $('.add-to-basket-alert').remove();
                                    }, 1500);
                                } else {
                                    if ($('.add-to-cart-messages').length === 0) {
                                        $('body').append(
                                            '<div class="add-to-cart-messages"></div>'
                                        );
                                    }
        
                                    $('#unavailable-modal').modal('hide');
                                    $('.add-to-cart-messages').append(
                                        '<div class="alert alert-success add-to-basket-alert text-center"'
                                        + ' role="alert">'
                                        + data.msgSuccess + '</div>'
                                    );
                                    setTimeout(function () {
                                        $('.add-to-basket-alert').remove();
                                    }, 3000);
                                }
                                $.spinner().stop();

                            },
                            error: function (err) {
                                $.spinner().stop();

                                console.log('Erro na requisição');
                            }
                        });
                    }
                });
            });
        
            function validateInput(name, email) {
                var valid = true;
                if (name.length < 1) {
                    $('.unavailable-name').css('border', '1px solid red');
                    $('.error-navailable-name').css({ 'display': 'block' })
                    valid = false;
                } else {
                    $('.error-navailable-name').css('display', 'none');
                    $('.unavailable-name').css('border', '1px solid black');
                }
                if (validateEmail(email) == false) {
                    $('.unavailable-email').css('border', '1px solid red');
                    $('.error-navailable-email').css({ 'display': 'block' });
                    valid = false;
                } else {
                    $('.error-navailable-email').css('display', 'none');
                    $('.unavailable-email').css('border', '1px solid black');
                }
                return valid;
            }
            function validateEmail(email) {
                var regex = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
                return regex.test(email);
            }
        }    }

 };