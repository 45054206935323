$(document).ready(function(){
    $('.seller-carousel').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: '<span class="slick-prev"></span>',
      nextArrow: '<span class="slick-next"></span>',

      responsive: [
          {
              breakpoint: 1280,
              settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1
              }
          },
          {
              breakpoint: 1200,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
              }
          },
          {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
          },
          {
              breakpoint: 576,
              settings: {
                  dots: true,
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
    });
    $('.seller-carousel').removeClass('skeleton-loaderCarousel');

    //quotation-sellers-carousel
    $('.quotation-sellers-carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      variableWidth: true,
      prevArrow: false,
      nextArrow: false
    });
});

$('body').on('sellerSlickCarousel', function() {
  setTimeout(() => {
    $('.quotation-sellers-carousel').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      variableWidth: true,
      prevArrow: false,
      nextArrow: false
    });
  }, 100);
});